<template>
  <div class="otras-ventas mx-2">
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title>Otras ventas</v-card-title>
          <v-card-text>
            <v-row dense>
              <!-- Seleccion inicial -->
              <v-col cols="12" md="6" sm="6">
                <v-card elevation="0" outlined>
                  <v-card-text>
                    <strong class="text-h5 font-weight-medium"></strong>
                    <v-row align="center" dense>
                      <v-col cols="12" md="12">
                        <v-btn
                          small
                          color="black"
                          dark
                          class="mb-4"
                          @click="dialog_form = true"
                          >Registrar Cliente</v-btn
                        >
                      </v-col>
                      <!-- Fecha -->
                      <v-col>
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date"
                              outlined
                              rounded
                              dense
                              label="Fecha"
                              append-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            scrollable
                            v-model="date"
                            locale="es-co"
                            color="primary"
                            first-day-of-week="1"
                            @input="menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <!-- Clientes -->
                      <v-col>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-autocomplete
                              v-model="id"
                              v-on="on"
                              v-bind="attrs"
                              dense
                              outlined
                              rounded
                              :loading="isLoading"
                              item-value="id"
                              :items="lista_clientes"
                              no-filter
                              label="Selecciona un cliente"
                              ref="clientes"
                              hide-no-data
                              @update:search-input="customFilterCliente"
                              @change="detailCliente()"
                            ></v-autocomplete>
                          </template>
                          <span
                            >Filtra tus clientes por nombre o cedula / ID</span
                          >
                        </v-tooltip>
                      </v-col>
                      <!-- Servicios -->
                      <v-col cols="12" md="12" sm="6">
                        <v-autocomplete
                          dense
                          outlined
                          rounded
                          v-model="servicio"
                          :loading="isLoadingS"
                          append-icon="mdi-magnify"
                          item-text="servicio"
                          :items="servicios"
                          :filter="customFilter"
                          label="Selecciona un servicio"
                          return-object
                          ref="servicio"
                          @change="addItem(servicio, 'servicio')"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Detalle cliente -->
              <v-col cols="12" md="6" sm="6">
                <v-card elevation="0" outlined v-if="cliente">
                  <v-card-text>
                    <strong class="text-h5 font-weight-medium">Cliente</strong>
                    <v-row align="center" no-gutters>
                      <v-col>
                        <strong style="color: #1976d2 !important">
                          {{ cliente.nombre }}
                        </strong>
                        <br />
                        <strong
                          >Cc:
                          <span style="color: #1976d2 !important">{{
                            cliente.cedula
                          }}</span>
                        </strong>
                        <br />
                        <strong
                          >Tel:
                          <span style="color: #1976d2 !important">{{
                            cliente.telefono
                          }}</span>
                        </strong>
                        <br />
                        <strong
                          >Puntos:
                          <span style="color: #1976d2 !important">{{
                            cliente.puntos
                          }}</span>
                        </strong>
                      </v-col>
                      <v-col align="right">
                        <v-avatar>
                          <img
                            :src="
                              $store.state.centro.route +
                                'clientes/' +
                                cliente.foto
                            "
                          />
                        </v-avatar>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Servicios añadidos -->
              <v-col
                cols="12"
                md="12"
                sm="12"
                v-for="(item, index) in lista"
                :key="index"
              >
                <v-card elevation="0" outlined>
                  <v-card-title>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mr-2"
                          elevation="0"
                          icon
                          color="error"
                          small
                          v-on="on"
                          v-bind="attrs"
                          @click="deleteItem('servicio', index)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>
                        Elimiar servicio
                      </span>
                    </v-tooltip>
                    {{ item.servicio }} - [IVA {{ item.iva }}%]
                  </v-card-title>
                  <v-card-text>
                    <v-row dense>
                      <v-col>
                        <v-text-field
                          type="number"
                          dense
                          outlined
                          rounded
                          v-model.number="item.valor"
                          label="Precio"
                          @change="updateValues(index)"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <div class="text-center">
                          <strong>Precio sin IVA</strong>
                          <h3>{{ item.sin_iva | currency }}</h3>
                        </div>
                      </v-col>
                      <!-- Productos de consumo -->
                      <v-col cols="12">
                        <h2>Productos de consumo</h2>
                        <v-autocomplete
                          class="mt-2"
                          style="width: 50%"
                          dense
                          outlined
                          rounded
                          v-model="producto"
                          append-icon="mdi-magnify"
                          item-text="filter"
                          :items="list_consumo"
                          :filter="customFilter"
                          label="Selecciona un producto"
                          return-object
                          :ref="`p_consumo_${index}`"
                          @change="addItem(producto, 'consumo', index)"
                        ></v-autocomplete>
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th></th>
                                <th>Codigo</th>
                                <th>Producto</th>
                                <th>Valor</th>
                                <th>Valor sin IVA</th>
                                <th>Gr / Uni</th>
                                <th>Subtotal</th>
                                <th>IVA</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(consumo, i_c) in item.consumos"
                                :key="i_c"
                              >
                                <td>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        color="error"
                                        v-on="on"
                                        v-bind="attrs"
                                        @click="
                                          deleteItem('consumo', index, i_c)
                                        "
                                      >
                                        <v-icon>mdi-delete</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Eliminar producto</span>
                                  </v-tooltip>
                                </td>
                                <td>
                                  {{ consumo.codigo }}
                                </td>
                                <td>{{ consumo.producto }}</td>
                                <td>{{ consumo.valor | currency }}</td>
                                <td>{{ consumo.sin_iva | currency }}</td>
                                <td>
                                  <v-text-field
                                    type="number"
                                    style="width: 140px"
                                    class="mt-2"
                                    dense
                                    outlined
                                    rounded
                                    v-model.number="consumo.gramos"
                                    label="Gr / Uni"
                                    hint="Gramos o Unidades"
                                    persistent-hint
                                    @change="updateValues(index)"
                                  ></v-text-field>
                                </td>
                                <td>{{ consumo.subtotal | currency }}</td>
                                <td>{{ consumo.iva | currency }}</td>
                                <td>{{ consumo.total | currency }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                      <!-- Profesionales para el servicio -->
                      <v-col cols="12">
                        <h2 class="mt-4">Profesionales</h2>
                        <v-autocomplete
                          class="mt-2"
                          style="width: 50%"
                          dense
                          outlined
                          rounded
                          v-model="profesional"
                          append-icon="mdi-magnify"
                          item-text="nombre"
                          :items="profesionales"
                          :filter="customFilter"
                          label="Selecciona un profesional"
                          return-object
                          :ref="`profesional_s_${index}`"
                          @change="addItem(profesional, 'profesional', index)"
                        ></v-autocomplete>
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th></th>
                                <th>Profesional</th>
                                <th>Porcentaje</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(prof, i_p) in item.profesionales"
                                :key="i_p"
                              >
                                <td>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        color="error"
                                        v-on="on"
                                        v-bind="attrs"
                                        @click="
                                          deleteItem('profesional', index, i_p)
                                        "
                                      >
                                        <v-icon>mdi-delete</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Eliminar profesional</span>
                                  </v-tooltip>
                                </td>
                                <td>
                                  {{ prof.profesional }}
                                </td>
                                <td>
                                  <v-text-field
                                    style="width: 160px"
                                    type="number"
                                    class="mt-2"
                                    dense
                                    outlined
                                    rounded
                                    v-model.number="prof.porcentaje"
                                    label="Porcentaje"
                                    hint="Porcentaje"
                                    persistent-hint
                                    @change="updateValues(index)"
                                  ></v-text-field>
                                </td>
                                <td>
                                  {{ prof.total | currency }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Productos de venta -->
              <v-col cols="12" md="12" sm="12">
                <v-card elevation="0" outlined>
                  <v-card-title>
                    Productos de venta
                  </v-card-title>
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-autocomplete
                          class="mt-2"
                          style="width: 50%"
                          dense
                          outlined
                          rounded
                          v-model="producto"
                          append-icon="mdi-magnify"
                          item-text="filter"
                          :items="list_venta"
                          :filter="customFilter"
                          label="Selecciona un producto"
                          ref="p_venta"
                          return-object
                          @change="addItem(producto, 'venta')"
                        ></v-autocomplete>
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th></th>
                                <th>Codigo</th>
                                <th>Producto</th>
                                <th>Cantidad</th>
                                <th>Precio</th>
                                <th>Vendedor</th>
                                <th>Subtotal</th>
                                <th>IVA</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(producto, index) in ventas"
                                :key="index"
                              >
                                <td>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        color="error"
                                        v-on="on"
                                        v-bind="attrs"
                                        @click="
                                          deleteItem('venta', index, null)
                                        "
                                      >
                                        <v-icon>mdi-delete</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Eliminar producto</span>
                                  </v-tooltip>
                                </td>
                                <td>
                                  {{ producto.codigo }}
                                </td>
                                <td>{{ producto.producto }}</td>
                                <td>
                                  <v-text-field
                                    style="width: 140px"
                                    type="number"
                                    class="mt-4"
                                    dense
                                    outlined
                                    rounded
                                    v-model.number="producto.cantidad"
                                    label="Gr / Uni"
                                    @change="updateVentas(index)"
                                  ></v-text-field>
                                </td>
                                <td>
                                  <v-text-field
                                    style="width: 140px"
                                    type="number"
                                    class="mt-4"
                                    dense
                                    outlined
                                    rounded
                                    v-model.number="producto.valor"
                                    label="Precio"
                                    @change="updateVentas(index)"
                                  ></v-text-field>
                                </td>
                                <td>
                                  <v-autocomplete
                                    class="mt-4"
                                    style="width: 140px"
                                    dense
                                    outlined
                                    rounded
                                    append-icon="mdi-magnify"
                                    item-text="nombre"
                                    item-value="id"
                                    v-model="producto.profesional_id"
                                    :items="profesionales"
                                    :filter="customFilter"
                                    label="Selecciona un profesional"
                                    @change="addVendedor"
                                  ></v-autocomplete>
                                </td>
                                <td>{{ producto.subtotal | currency }}</td>
                                <td>{{ producto.iva | currency }}</td>
                                <td>{{ producto.total | currency }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Medios de pago -->
              <v-col cols="12" md="6" sm="6">
                <v-card elevation="0" outlined>
                  <v-card-title>
                    Medios de pago
                  </v-card-title>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" md="6" sm="6">
                        <strong
                          >Efectivo: {{ pago.efectivo | currency }}</strong
                        >
                        <v-text-field
                          type="number"
                          class="mt-2"
                          dense
                          outlined
                          rounded
                          v-model.number="pago.efectivo"
                          label="Efectivo"
                          hint="Total en efectivo"
                          persistent-hint
                          @click="setTotal"
                          @change="changeValues($event, 'efectivo')"
                          id="efectivo"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <strong
                          >Transferencia: {{ pago.transfer | currency }}</strong
                        >
                        <v-text-field
                          type="number"
                          class="mt-2"
                          dense
                          outlined
                          rounded
                          v-model.number="pago.transfer"
                          label="Transferencia"
                          hint="Total en transferencia"
                          persistent-hint
                          @click="setTotal"
                          @change="changeValues($event, 'transfer')"
                          id="transfer"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <strong
                          >Datafono: {{ pago.datafono | currency }}</strong
                        >
                        <v-text-field
                          type="number"
                          class="mt-2"
                          dense
                          outlined
                          rounded
                          v-model.number="pago.datafono"
                          label="Datafono"
                          hint="Total en datafono"
                          persistent-hint
                          @click="setTotal"
                          @change="changeValues($event, 'datafono')"
                          id="datafono"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <strong>Puntos: {{ pago.puntos }}</strong>
                        <v-text-field
                          type="number"
                          class="mt-2"
                          dense
                          outlined
                          rounded
                          v-model.number="pago.puntos"
                          label="puntos"
                          hint="Total en puntos"
                          persistent-hint
                          @click="setTotal"
                          @change="changeValues($event, 'puntos')"
                          id="puntos"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <strong>Bono regalo</strong>
                        <v-autocomplete
                          class="mt-2"
                          dense
                          outlined
                          rounded
                          :loading="isLoadingB"
                          append-icon="mdi-magnify"
                          :items="lista_bonos"
                          :filter="customFilter"
                          label="Selecciona un bono"
                          return-object
                          v-model="pago.bono"
                          clearable
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        sm="6"
                        v-if="pago.bono != undefined"
                      >
                        <strong>Valor bono</strong>
                        <v-text-field
                          type="number"
                          class="mt-2"
                          dense
                          outlined
                          rounded
                          v-model.number="valor_bono"
                          label="Valor bono"
                          id="valor_bono"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <strong
                          >cortesia: {{ pago.cortesia | currency }}</strong
                        >
                        <v-text-field
                          type="number"
                          class="mt-2"
                          dense
                          outlined
                          rounded
                          v-model.number="pago.cortesia"
                          @change="changeValues($event, 'cortesia')"
                          label="cortesia"
                          id="cortesia"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <strong
                          >Anticipos:
                          {{ pago.valor_anticipos | currency }}</strong
                        >
                        <v-select
                          class="mt-2"
                          label="Anticipos"
                          :items="lista_anticipos"
                          dense
                          rounded
                          outlined
                          multiple
                          v-model="pago.anticipos"
                          @change="addAnticipo()"
                          return-object
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="12" sm="12">
                        <strong>Observaciones</strong>
                        <v-textarea
                          rows="2"
                          no-resize
                          outlined
                          counter
                          auto-grow
                          v-model="observacion"
                        ></v-textarea>
                        <v-btn
                          :dark="!loading"
                          :color="!loading ? 'black' : 'gray'"
                          :loading="loading"
                          :disabled="loading"
                          @click="pagar()"
                          >Pagar</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Totalizacion -->
              <v-col cols="12" md="6" sm="6">
                <v-card elevation="0" outlined>
                  <v-card-title>
                    Totalizacion
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <th>Productos de consumo</th>
                            <td>
                              {{ total_consumos | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>Pago profesionales</th>
                            <td>{{ total_profesionales | currency }}</td>
                          </tr>
                          <tr>
                            <th>Caja</th>
                            <td>{{ caja | currency }}</td>
                          </tr>
                          <tr>
                            <th>Total servicios</th>
                            <td>{{ total_servicios | currency }}</td>
                          </tr>
                          <tr>
                            <th>Puntos x servicio</th>
                            <td>
                              <v-text-field
                                type="number"
                                class="mt-2"
                                dense
                                outlined
                                rounded
                                v-model.number="_puntos"
                                label="Puntos"
                                hint="Ingresa la cantidad de puntos"
                                persistent-hint
                              ></v-text-field>
                            </td>
                          </tr>
                          <tr>
                            <th>Total productos de venta</th>
                            <td>{{ total_ventas | currency }}</td>
                          </tr>
                          <tr>
                            <th>IVA servicios</th>
                            <td>{{ iva_servicios | currency }}</td>
                          </tr>
                          <tr>
                            <th>IVA productos de consumo</th>
                            <td>
                              {{ iva_consumos | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>IVA productos de venta</th>
                            <td>{{ iva_ventas | currency }}</td>
                          </tr>
                          <tr>
                            <th>Total IVA</th>
                            <td>
                              {{
                                (iva_consumos + iva_servicios + iva_ventas)
                                  | currency
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>Propina</th>
                            <td>
                              <v-text-field
                                type="number"
                                class="mt-2"
                                dense
                                outlined
                                rounded
                                v-model.number="propina"
                                label="Propina"
                                hint="Ingresa el valor de la propina"
                                persistent-hint
                                @change="changeValues($event, 'propina')"
                              ></v-text-field>
                            </td>
                          </tr>
                          <tr>
                            <th>Total</th>
                            <td>
                              {{
                                parseFloat(
                                  (
                                    total_servicios +
                                    total_ventas +
                                    (iva_servicios + iva_ventas) +
                                    propina
                                  ).toFixed(2)
                                ) | currency
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-dialog v-model="dialog" persistent max-width="600px" scrollable>
          <v-card>
            <v-card-title class="headline">
              Pago
            </v-card-title>

            <v-card-text>
              <set-document
                v-if="documentoElectronico"
                :electronico="electronico.electronico"
                :factura_id="electronico.factura_id"
                v-on:setCancel="setCancel($event)"
                v-on:setCancelEnvio="setCancelEnvio()"
                v-on:setPrint="setPrint($event)"
              />
              <h3 v-if="!documentoElectronico">
                <strong>
                  El pago se ha efectuado
                </strong>
              </h3>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="error" text @click="closePago()" v-if="cancel_btn">
                Cerrar
              </v-btn>

              <v-btn color="black" dark @click="print()" v-if="print_post">
                <v-icon left>mdi-printer</v-icon>
                Imprimir
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_form" persistent scrollable max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Crear cliente</span>
            </v-card-title>
            <v-card-text
              ><v-row>
                <v-col cols="12" md="12" sm="12">
                  <formComponent :list="form" ref="clienteForm" />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="cancelForm()" color="primary">
                Cancelar
              </v-btn>
              <v-btn
                :dark="!loading"
                :color="!loading ? 'black' : 'gray'"
                :loading="loading"
                :disabled="loading"
                @click="registerCliente()"
              >
                Crear
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import setDocument from "../../../components/SetDocument.vue";
import formComponent from "../../../components/Form.vue";
import * as moment from "moment";
moment.locale("es");
export default {
  name: "otrasVentas",
  components: {
    setDocument,
    formComponent,
  },
  data: () => ({
    id: null,
    search: "",
    // Call
    facturacion: null,
    isLoading: false,
    clientes: [],
    servicios: [],
    profesionales: [],
    productos: [],
    bonos: [],

    // Render
    loading: false,
    menu: false,
    date: moment().format("YYYY-MM-DD"),
    cliente: null,
    isLoadingS: false,
    servicio: null,
    profesional: null,
    producto: null,
    isLoadingB: false,
    documentoElectronico: false,
    electronico: null,
    dialog: false,
    propina: 0,
    pago: {
      efectivo: 0,
      transfer: 0,
      datafono: 0,
      puntos: 0,
      bono: null,
      valor_bono: 0,
      cortesia: 0,
      anticipos: [],
      valor_anticipos: 0,
    },
    observacion: "",
    factura_id: null,
    puntos: 0,
    print_post: false,
    cancel_btn: true,

    anticipos: [],

    // Elementos por facturar
    lista: [],
    ventas: [],
    dialog_form: false,
    form: {
      ref: "clientesRegister",
      list: [
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: {
            type: "input",
            input_type: "number",
            label: "ID / CC",
          },
          validation: {
            valid: true,
            rules: "required",
          },
          id: "cedula",
          model: "",
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: {
            type: "input",
            input_type: "text",
            label: "Cliente",
          },
          validation: {
            valid: true,
            rules: "required",
          },
          id: "nombre",
          model: "",
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: {
            type: "input",
            input_type: "text",
            label: "Correo",
          },
          validation: {
            valid: true,
            rules: "required|email",
          },
          id: "email",
          model: "",
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: {
            type: "input",
            input_type: "text",
            label: "Dirección",
          },
          validation: {
            valid: false,
            rules: "",
          },
          id: "direccion",
          model: "",
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 4,
            sm: 4,
          },
          input: {
            type: "input",
            input_type: "number",
            label: "Telefono",
          },
          validation: {
            valid: false,
            rules: "",
          },
          id: "telefono",
          model: "",
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 4,
            sm: 4,
          },
          input: "picker",
          menu: {
            model: false,
            label: "Cumpleaños",
          },
          validation: {
            valid: false,
          },
          id: "fecha",
          model: moment().format("YYYY-MM-DD"),
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 4,
            sm: 4,
          },
          input: "select",
          select: {
            label: "Genero",
            items: [
              { text: "Masculino", value: "m" },
              { text: "Femenino", value: "f" },
              { text: "Otros", value: "o" },
            ],
            hint: "Selecciona un genero",
            multiple: false,
          },
          validation: {
            valid: true,
            rules: "required",
          },
          id: "genero",
          model: "f",
          show: true,
        },
      ],
    },
  }),
  methods: {
    // Call
    loadFacturacion() {
      const body = {
        route: "/facturacion_data",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.facturacion = response.data.data;
          this.observacion = this.facturacion.notas;
        }
      });
    },
    loadClientesLite() {
      this.clientes = [];
      this.isLoading = true;
      const body = {
        route: `/clientes_lite`,
        params: {
          search: this.search,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.clientes = response.data.data;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    detailCliente() {
      const body = {
        route: "/detalle_cliente",
        params: {
          id: this.id,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.cliente = response.data.data;
          }
        })
        .finally(() => {
          this.$refs.clientes.reset();
          this.search = "";
          this.loadAnticipos();
        });
    },
    loadServicios() {
      this.isLoadingS = true;
      const body = {
        route: "/servicios_activos",
      };
      this.$store
        .dispatch("axios_get", body)
        .then((response) => {
          if (response.data.success) {
            this.servicios = response.data.data;
            this.servicios = this.servicios.map((servicio) => {
              servicio.duracion = parseInt(servicio.duracion);
              return servicio;
            });
          }
        })
        .finally(() => (this.isLoadingS = false));
    },
    loadProfesionales() {
      const body = {
        route: "/profesionales_disponibles",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.profesionales = response.data.data;
        }
      });
    },
    loadProductos() {
      const body = {
        route: "/productos_activos",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.productos = response.data.data;
        }
      });
    },
    loadAnticipos() {
      const body = {
        route: "/anticipos_cliente",
        params: {
          id: this.cliente.id,
        },
      };

      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.anticipos = response.data.data;
        }
      });
    },
    loadBonos() {
      this.isLoadingB = true;
      const body = {
        route: "/bonos_activos",
      };
      this.$store
        .dispatch("axios_get", body)
        .then((response) => {
          if (response.data.success) {
            this.bonos = response.data.data;
          }
        })
        .finally(() => (this.isLoadingB = false));
    },

    // Filtros
    customFilter(item, queryText) {
      let type = "";
      const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };

      if (item.text != undefined && type == "") type = "text";

      if (item.servicio != undefined && type == "") type = "servicio";

      if (item.nombre != undefined && type == "") type = "nombre";

      if (item.filter != undefined && type == "") type = "filter";

      const text = removeAccents(queryText);
      const textOne = removeAccents(item[type]).toLowerCase();
      const searchText = text.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    customFilterCliente(item) {
      this.search = item;
      if (this.search != null || this.search != undefined) {
        if (this.search != "") this.loadClientesLite();
      }
    },

    // Añadir elementos por facturar
    addItem(item, type, index = null) {
      let body;
      let iva;
      if (type == "servicio") {
        iva = 0;
        if (this.facturacion.regimen == "comun") {
          iva = parseFloat(item.iva);
        }
        let sin_iva = parseFloat(item.valor) / ((100 + iva) / 100);
        body = {
          servicio: item.servicio,
          id: item.id_activo,
          valor: parseFloat(item.valor),
          iva: iva,
          sin_iva: parseFloat(sin_iva.toFixed(2)),
          consumos: [],
          profesionales: [],
        };

        this.lista.push(body);
        this.servicio = null;
        this.$refs.servicio.reset();
      } else if (type == "profesional") {
        body = {
          profesional: item.nombre,
          id: item.id,
          porcentaje: 0,
          total: 0,
        };
        this.lista[index].profesionales.push(body);
        this.profesional = {};
        this.$refs[`profesional_s_${index}`][0].reset();
      } else if (type == "consumo") {
        iva = 0;
        if (this.facturacion.regimen == "documento") {
          iva = parseFloat(item.iva_venta);
        }
        let sin_iva = parseFloat(item.venta) / ((100 + parseFloat(iva)) / 100);
        body = {
          id: item.id_activo,
          codigo: item.codigo,
          producto: item.producto,
          valor: parseFloat(item.venta),
          sin_iva: parseFloat(sin_iva.toFixed(2)),
          iva_venta: iva,
          gramos: 0,
          subtotal: 0,
          iva: 0,
          total: 0,
        };
        this.lista[index].consumos.push(body);
        this.producto = null;
        this.$refs[`p_consumo_${index}`][0].reset();
      } else if (type == "venta") {
        let profesional_id = null

        if(this.ventas.length > 0){
          profesional_id = this.ventas[this.ventas.length -1].profesional_id
        }
        iva = 0;
        if (this.facturacion.regimen != "simplificado") {
          iva = parseFloat(item.iva_venta);
        }
        body = {
          id: item.id_activo,
          codigo: item.codigo,
          producto: item.producto,
          valor: parseFloat(item.venta),
          iva_venta: iva,
          cantidad: 1,
          subtotal: parseFloat(
            (parseFloat(item.venta) / ((100 + iva) / 100)).toFixed(2)
          ),
          iva: parseFloat(
            (
              ((parseFloat(item.venta) / ((100 + iva) / 100)) * iva) /
              100
            ).toFixed(2)
          ),
          total: parseFloat(item.venta),
          profesional_id,
        };
        this.ventas.push(body);
        this.producto = null;
        this.$refs.p_venta.reset();
      }
    },

    addAnticipo() {
      const valor = this.pago.anticipos.reduce((index, item) => {
        return index + item.valor;
      }, 0);

      this.pago.valor_anticipos = valor;
    },

    // Eliminar elementos por facturar
    deleteItem(type, primary_index, secondary_index = null) {
      if (type == "servicio") {
        this.lista.splice(primary_index, 1);
      } else if (type == "profesional") {
        this.lista[primary_index].profesionales.splice(secondary_index, 1);
        this.updateValues(primary_index);
      } else if (type == "consumo") {
        this.lista[primary_index].consumos.splice(secondary_index, 1);
        this.updateValues(primary_index);
      } else if (type == "venta") {
        this.ventas.splice(primary_index, 1);
      }
    },

    // Actualizar valores facturados
    updateValues(index) {
      let servicio = this.lista[index];
      let iva_servicio = 0;
      if (this.facturacion.regimen != "simplificado") {
        iva_servicio = parseFloat(servicio.iva);
      }
      let sin_iva_servicio =
        parseFloat(servicio.valor) / ((100 + iva_servicio) / 100);
      servicio.sin_iva = sin_iva_servicio;
      servicio.consumos.forEach((consumo) => {
        consumo.gramos = consumo.gramos == "" ? 0 : consumo.gramos;
        consumo.subtotal = parseFloat(
          (consumo.sin_iva * consumo.gramos).toFixed(2)
        );
        consumo.iva = parseFloat(
          ((consumo.valor - consumo.sin_iva) * consumo.gramos).toFixed(2)
        );
        consumo.total = consumo.valor * consumo.gramos;
      });
      const productos = servicio.consumos.reduce((index, item) => {
        return parseFloat((index + parseFloat(item.total)).toFixed(2));
      }, 0);

      servicio.profesionales.forEach((profesional) => {
        profesional.porcentaje =
          profesional.porcentaje == "" ? 0 : profesional.porcentaje;
        profesional.total = parseFloat(
          (
            ((servicio.sin_iva - productos) * profesional.porcentaje) /
            100
          ).toFixed(2)
        );
      });
      this.lista[index] = servicio;
    },

    updateVentas(index) {
      let venta = this.ventas[index];
      venta.cantidad = venta.cantidad == "" ? 0 : venta.cantidad;
      venta.valor = venta.valor == "" ? 0 : venta.valor;
      venta.subtotal = Math.round(
        (parseFloat(venta.valor) / ((100 + venta.iva_venta) / 100)) *
          venta.cantidad
      );
      venta.iva = Math.round(
        (((parseFloat(venta.valor) / ((100 + venta.iva_venta) / 100)) *
          venta.iva_venta) /
          100) *
          venta.cantidad
      );
      venta.total = venta.valor * venta.cantidad;
    },

    changeValues(event, target) {
      if (target == "propina") {
        this.propina = event == "" ? 0 : parseFloat(event);
      } else {
        this.pago[target] = event == "" ? 0 : parseFloat(event);
      }
    },

    setTotal(data) {
      if (
        this.pago.efectivo == 0 &&
        this.pago.transfer == 0 &&
        this.pago.datafono == 0 &&
        this.pago.puntos == 0
      ) {
        this.pago[data.target.id] =
          this.total_servicios +
          this.total_ventas +
          (this.iva_servicios + this.iva_ventas) +
          this.propina;

        if (data.target.id == "puntos") {
          this.pago[data.target.id] = this.pago[data.target.id] / 1000;
        }

        if (isNaN(this.pago[data.target.id])) this.pago[data.target.id] = 0;
      }
    },

    closePago() {
      this.dialog = false;
      this.$router.push("/agenda");
    },

    print() {
      const route = this.$router.resolve({ path: "/pos/" + this.factura_id });
      this.dialog = false;
      this.$router.push("/agenda");
      window.open(route.href, "_blank");
    },

    setCancel(input) {
      this.cancel_btn = input;
    },

    setCancelEnvio() {
      this.closePago();
    },

    setPrint(input) {
      this.print_post = input;
    },

    distribucionValidate() {
      let distribucion = true;
      for (let item of this.lista) {
        if (item.profesionales.length == 0) {
          distribucion = false;
          break;
        }
      }
      return distribucion;
    },

    pagar() {
      this.loading = true;
      const distribucion = this.distribucionValidate();
      const total = Math.round(
        this.total_servicios +
          this.total_ventas +
          (this.iva_servicios + this.iva_ventas) +
          this.propina
      );
      const total_pago =
        this.pago.efectivo +
        this.pago.transfer +
        this.pago.datafono +
        this.pago.valor_bono +
        this.pago.puntos * 1000 +
        this.pago.cortesia +
        this.pago.valor_anticipos;
      if (this.cliente) {
        if (this.lista.length == 0 && this.ventas.length == 0) {
          let alert = {
            alert: true,
            color: "error",
            text: "No se ingresaron valores a la factura",
          };
          this.$store.dispatch("setAlert", alert);
        } else {
          if (distribucion) {
            if (total_pago >= total) {
              const fecha_actual = moment().format("YYYY-MM-DD");
              if (!moment(this.date).isAfter(fecha_actual)) {
                let body = {
                  route: "/pago",
                  data: {
                    factura: {
                      datos_facturacion: this.facturacion.id,
                      cita_id: null,
                      cliente_id: this.cliente.id,
                      cliente: this.cliente.nombre,
                      cc_cliente: this.cliente.cedula,
                      telefono: this.cliente.telefono,
                      direccion: this.cliente.direccion,
                      propina: this.propina,
                      subtotal: parseFloat(
                        (
                          this.total_servicios +
                          this.total_ventas -
                          this.iva_consumos
                        ).toFixed(2)
                      ),
                      iva: parseFloat(
                        (
                          this.iva_servicios +
                          this.iva_consumos +
                          this.iva_ventas
                        ).toFixed(2)
                      ),
                      total: parseFloat(
                        (
                          this.total_servicios +
                          this.total_ventas +
                          this.iva_servicios +
                          this.iva_ventas
                        ).toFixed(2)
                      ),
                      efectivo: this.pago.efectivo,
                      transfer: this.pago.transfer,
                      datafono: this.pago.datafono,
                      puntos: this.pago.puntos,
                      bono_id:
                        this.pago.bono != undefined ? this.pago.bono.id : null,
                      bono_valor: this.pago.valor_bono,
                      total_anticipo: this.pago.valor_anticipos,
                      cortesia: this.pago.cortesia,
                      tipo: "",
                      observacion:
                        this.observacion == "" ? null : this.observacion,
                      fecha: this.date,
                      vencimiento: this.date,
                    },
                    caja: {
                      categoria: "caja",
                      porcentaje: 0,
                      total: this.caja,
                    },
                    distribucion: [],
                    salidas: [],
                    anticipos: this.pago.anticipos,
                    puntos: this.puntos,
                    created: moment().format("YYYY-MM-DD HH:mm:ss"),
                    time: moment().format("HH:mm:ss"),
                  },
                };
                this.lista.forEach((item, index) => {
                  let order_servicio = index + 1;
                  let servicio = {
                    servicio_id: item.id,
                    producto_id: null,
                    salida: item.servicio,
                    profesional_id: null,
                    categoria: "servicio",
                    cantidad: 1,
                    subtotal: item.sin_iva,
                    iva: parseFloat(
                      (
                        ((item.valor / ((100 + item.iva) / 100)) * item.iva) /
                        100
                      ).toFixed(2)
                    ),
                    total: item.valor,
                    order_list: order_servicio,
                  };
                  body.data.salidas.push(servicio);
                  item.profesionales.forEach((profesional, index_p) => {
                    let order_pr = `${order_servicio}.${index_p + 1}`;
                    let profesionales = {
                      profesional_id: profesional.id,
                      servicio_id: item.id,
                      categoria: "profesional",
                      porcentaje: profesional.porcentaje,
                      total: profesional.total,
                      order_list: order_pr,
                    };
                    body.data.distribucion.push(profesionales);
                  });
                  item.consumos.forEach((consumo, index_c) => {
                    let order_c = `${order_servicio}.${index_c + 1}`;
                    let p_consumo = {
                      servicio_id: item.id,
                      producto_id: consumo.id,
                      salida: consumo.producto,
                      profesional_id: null,
                      categoria: "consumo",
                      cantidad: consumo.gramos,
                      subtotal: consumo.subtotal,
                      iva: consumo.iva,
                      total: consumo.total,
                      order_list: order_c,
                    };
                    body.data.salidas.push(p_consumo);
                  });
                });
                this.ventas.forEach((venta) => {
                  let p_venta = {
                    servicio_id: null,
                    producto_id: venta.id,
                    salida: venta.producto,
                    profesional_id: venta.profesional_id,
                    categoria: "venta",
                    cantidad: venta.cantidad,
                    subtotal: venta.subtotal,
                    iva: venta.iva,
                    total: venta.total,
                    order_list: null,
                  };
                  body.data.salidas.push(p_venta);
                });
                if (this.lista.length > 0) {
                  body.data.factura.tipo = "Servicio sin agendar";
                }
                if (this.lista.length == 0 && this.ventas.length > 0) {
                  body.data.factura.tipo = "Venta producto";
                }
                this.$store
                  .dispatch("axios_post", body)
                  .then((response) => {
                    if (response.data.success) {
                      this.factura_id = response.data.data.id;
                      this.documentoElectronico =
                        response.data.data.electronico.authorization;

                      if (this.documentoElectronico) this.cancel_btn = false;
                      else this.print_post = true;

                      this.electronico = {
                        electronico: response.data.data.electronico,
                        factura_id: this.factura_id,
                      };
                      this.dialog = true;
                    }
                  })
                  .catch((error) => {
                    let alert = {
                      alert: true,
                      color: "error",
                      text: error.response.data.data,
                    };
                    this.$store.dispatch("setAlert", alert);
                    if (error.response.status != 409) {
                      this.$router.push("/agenda");
                    }
                  })
                  .finally(() => (this.loading = false));
              } else {
                let alert = {
                  alert: true,
                  color: "error",
                  text:
                    "No puedes registrar pagos posteriores a la fecha actual",
                };
                this.$store.dispatch("setAlert", alert);
                this.loading = false;
              }
            } else {
              let alert = {
                alert: true,
                color: "error",
                text: "El valor ingresado no puede ser menor al total a pagar",
              };
              this.$store.dispatch("setAlert", alert);
              this.loading = false;
            }
          } else {
            let alert = {
              alert: true,
              color: "error",
              text: "No se distribuyo correctamente el servicio",
            };
            this.$store.dispatch("setAlert", alert);
            this.loading = false;
          }
        }
      } else {
        let alert = {
          alert: true,
          color: "error",
          text: "No se selecciono un cliente",
        };
        this.$store.dispatch("setAlert", alert);
        this.loading = false;
      }
    },
    registerCliente() {
      this.$refs.clienteForm.validate().then((res) => {
        if (res) {
          this.loading = true;
          let body = {
            route: "/register_cliente",
            data: {
              data: this.$store.getters.validation_form(this.form.list),
            },
          };
          body.data.data.telefono =
            body.data.data.telefono == "" ? "0000000" : body.data.data.telefono;
          body.data.data.direccion =
            body.data.data.direccion == ""
              ? "No registra"
              : body.data.data.direccion;
          this.$store
            .dispatch("axios_post", body)
            .then((response) => {
              if (response.data.success) {
                let alert = {
                  alert: true,
                  color: "success",
                  text: response.data.data,
                };
                this.$store.dispatch("setAlert", alert);
                this.cancelForm();
              }
            })
            .catch((error) => {
              this.loading = false;
              let alert = {
                alert: true,
                color: "error",
                text: error.response.data.data,
              };
              this.$store.dispatch("setAlert", alert);
            });
        }
      });
    },
    addVendedor(value){
      this.ventas.forEach(venta => {
        if(!venta.profesional_id){
          venta.profesional_id = value
        }
      });
    },
    cancelForm() {
      this.loading = false;
      this.dialog_form = false;
      this.setForm();
      this.$refs.clienteForm.reset();
    },
    setForm() {
      this.form.list.forEach((item) => {
        if (item.id == "puntos" || item.id == "estado") {
          item.show = false;
        }
        if (item.id != undefined) {
          item.model = "";
          if (item.id == "fecha") {
            item.model = moment().format("YYYY-MM-DD");
          }

          if (item.id == "genero") {
            item.model = "f";
          }
        }
      });
    },
  },
  created() {
    // this.loadClientesLite();
    this.loadFacturacion();
    this.loadServicios();
    this.loadProfesionales();
    this.loadProductos();
    this.loadBonos();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["recepcion"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    lista_clientes() {
      return this.clientes.map((cliente) => {
        return {
          text: `${cliente.nombre} [${cliente.cedula}]`,
          nombre: cliente.nombre,
          cedula: cliente.cedula,
          id: cliente.id,
        };
      });
    },
    productosLista() {
      const productos = this.productos.map((producto) => {
        producto.compra = parseFloat(producto.compra);
        producto.venta = parseFloat(producto.venta);
        producto.iva_compra = parseFloat(producto.iva_compra);
        producto.iva_venta = parseFloat(producto.iva_venta);
        producto.inventario = parseInt(producto.inventario);
        let data = producto.producto + " [" + producto.codigo + "]";
        Object.assign(producto, { filter: data });
        return producto;
      });
      return productos;
    },
    list_venta() {
      return this.productosLista.filter((producto) => {
        return producto.inventario == 1;
      });
    },
    list_consumo() {
      return this.productosLista.filter((producto) => {
        return producto.inventario == 2;
      });
    },
    lista_bonos() {
      return this.bonos
        .filter((b) => {
          return b.estado == 0;
        })
        .map((b) => {
          let saldo = parseFloat(b.valor) - parseFloat(b.saldo);
          return {
            text: `${b.codigo} [${b.cliente} - ${this.$options.filters.currency(
              parseFloat(saldo)
            )}]`,
            valor: parseFloat(saldo),
            id: b.id,
          };
        });
    },
    lista_anticipos() {
      return this.anticipos.map((anticipo, index) => {
        return {
          text: `${index + 1} - ${this.$options.filters.currency(
            anticipo.valor
          )}`,
          valor: parseFloat(anticipo.valor),
          id: anticipo.id,
        };
      });
    },
    valor_bono: {
      get() {
        let valor = 0;
        if (this.pago.bono != null) {
          valor = this.pago.bono.valor;
        } else {
          valor = 0;
        }
        this.pago.valor_bono = valor;
        return valor;
      },
      set(val) {
        if (val == "" || val > this.pago.bono.valor || val < 0) {
          this.pago.valor_bono = this.pago.bono.valor;
        } else {
          this.pago.valor_bono = val;
        }
      },
    },
    total_consumos() {
      return parseFloat(
        this.lista
          .reduce((index, item) => {
            return (
              index +
              item.consumos.reduce((i, consumo) => {
                return i + consumo.subtotal;
              }, 0)
            );
          }, 0)
          .toFixed(2)
      );
    },
    total_profesionales() {
      return parseFloat(
        this.lista
          .reduce((index, item) => {
            return (
              index +
              item.profesionales.reduce((i, profesional) => {
                return i + profesional.total;
              }, 0)
            );
          }, 0)
          .toFixed(2)
      );
    },
    total_servicios() {
      return parseFloat(
        this.lista
          .reduce((index, item) => {
            return index + item.sin_iva;
          }, 0)
          .toFixed(2)
      );
    },
    total_ventas() {
      return parseFloat(
        this.ventas
          .reduce((index, item) => {
            return index + item.subtotal;
          }, 0)
          .toFixed(2)
      );
    },
    iva_consumos() {
      return parseFloat(
        this.lista
          .reduce((index, item) => {
            return (
              index +
              item.consumos.reduce((i, consumo) => {
                return i + consumo.iva;
              }, 0)
            );
          }, 0)
          .toFixed(2)
      );
    },
    iva_servicios() {
      return parseFloat(
        this.lista
          .reduce((index, item) => {
            return (
              index + ((item.valor / ((100 + item.iva) / 100)) * item.iva) / 100
            );
          }, 0)
          .toFixed(2)
      );
    },
    iva_ventas() {
      return parseFloat(
        this.ventas
          .reduce((index, item) => {
            return index + item.iva;
          }, 0)
          .toFixed(2)
      );
    },
    caja() {
      const subtotal = parseFloat(
        this.lista
          .reduce((index, item) => {
            return index + item.sin_iva;
          }, 0)
          .toFixed(2)
      );

      return parseFloat(
        (
          subtotal -
          this.total_profesionales -
          this.total_consumos -
          this.iva_consumos
        ).toFixed(2)
      );
    },
    _puntos: {
      get() {
        this.puntos = parseInt(this.total_servicios / 1000);
        return parseInt(this.total_servicios / 1000);
      },
      set(val) {
        this.puntos = val == "" ? 0 : parseInt(val);
      },
    },
  },
  watch: {
    id(val) {
      if (val != undefined || val != null) this.$refs.clientes.reset();
      return;
    },
  },
};
</script>
